@import url("https://fonts.googleapis.com/css?family=Avenir:wght@400;600,700");
/* @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700&display=swap"); */

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
header,
section {
  margin: 0;
}

button,
input {
  border: none;
  outline: none;
  padding: 8px;
}

.no-inherit{
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
  max-width: 230px;
  margin-bottom: 20px !important;
  word-wrap: break-word !important;
}

.no-inherits{
  display: table-cell;
  padding: 10px;
  height: auto;
  vertical-align: middle;
  margin-bottom: 20px !important;
  word-wrap: break-word !important;
}

section {
  width: 100%;
  margin: 0;
}

.p-r {
  position: relative !important;
}

.pad {
  padding: 20px !important;
}

.no-padding {
  padding: 0 !important;
}
.p-20 {
  padding: 20px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}

.no-margin {
  margin: 0 !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t--20 {
  margin-top: -20px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.no-border {
  border: none !important;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.wrap {
  flex-wrap: wrap !important;
}
.flex {
  display: flex !important;
}
.flex-h-center {
  justify-content: center !important;
}

.flex_basis_30 {
  flex-basis: 30%;
}

.flex_basis_48 {
  flex-basis: 48%;
}

.flex_basis_100 {
  flex-basis: 100%;
}
.flex-v-center {
  align-items: center !important;
}
.pointer {
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.center-text {
  text-align: center !important;
}
.right-text {
  text-align: right !important;
}
.space-between {
  justify-content: space-between !important;
}
.float-right {
  float: right !important;
}
.inline-block {
  display: inline-block !important;
}
.bold-text {
  font-weight: bold !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-500 {
  max-width: 500px !important;
}

.max-w-800 {
  max-width: 800px !important;
}

textarea{
  padding: 8px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-half,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
  height: auto;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6,
.col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12,
.w-100 {
  width: 100% !important;
  position: relative;
}
.row {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.flex-end {
  justify-content: end;
}

.h-100 {
  height: 100%;
}

.td-dash{
  cursor: pointer;
  height: 40px;
padding: 10px;}
.td-dash:hover{
  background-color: #07af4d81;
}
.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url("../images/banner.png");
  background-color: #1c811c55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #c6c6c6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  color: #777;
}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017d34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017d3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #ffffff;
  box-shadow: 5px 3px 22px #958e8e29;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1c811c;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1c811c1f;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #c6c6c6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1c811c;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    height: auto;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    height: auto;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px !important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}

.hover-green:hover {
  background-color: #1c811c;
}

.flex-direction-column {
  flex-direction: column;
}
.white-color {
  background-color: white !important;
}
.w-30 {
  width: 30% !important;
}
.w-20 {
  width: 20% !important;
}
.w-15 {
  width: 15% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-80 {
  width: 80% !important;
}
.m-30 {
  margin: 30px;
}

.m-r-30 {
  margin-right: 30px;
}

.assess_text_area {
  width: 100%;
  height: 150px;
  padding: 16px;
  margin-top: 16px;
  background: #c0dbc031 0% 0% no-repeat padding-box;
  border: 2px solid #b2d4b2;
  border-radius: 2px;
}

.staff_text_area {
  width: 100%;
  height: 350px;
  padding: 16px;
  margin-top: 16px;
  background: #c0dbc031 0% 0% no-repeat padding-box;
  border: 2px solid #b2d4b2;
  border-radius: 2px;
}

.career_button {
  border-radius: 5px 5px 0 0;
  width: 250px;
}

.career_button1 {
  border-radius: 5px 5px 0 0;
  width: 450px;
}

.deactivate {
  background-color: pink;
}

.circle-pic {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  object-fit: contain;
  border: 1px solid green;
}

.bg-light-green {
  background-color: #1c811c !important;
}

.collapse {
  border-collapse: collapse;
}
.info-box {
  background-color: #43425d;
  color: white;
  padding: 16px;
}

.bottom-border {
  border-bottom: 2px solid #43425d;
}

.border-test {
  border: 1px solid red;
}
.annual_button {
  border-radius: 5px 5px 0 0;
  border: 1px solid #1c811c;
  padding: 8px 15px;
  cursor: pointer;
  width: 200px;
  border-bottom: 4px solid #1c811c;
}

.annual_button1 {
  border-radius: 5px 5px 0 0;
  border: 1px solid #e9e9f0;
  padding: 8px 15px;
  cursor: pointer;
  width: 200px;
  color: #43425d;
  opacity: 0.5;
  background-color: #f5f7f9;
}
