.custom-collapsible-table {
  .custom-collapsible-table-header {
    background-color: #d5ffd5;
    width: 100%;
    margin-bottom: 1rem;

    & > .c-column {
      font-weight: bolder;
      margin-top: 1rem;
    }
  }

  .divider {
    border: 1px solid #d6d6d6;
  }

  .additional-comments-container {
    margin-bottom: 2rem;

    textarea {
      width: 100%;
      border: 1px solid #b2d4b2;
      background-color: #c0dbc031;
      padding: 0.5rem;
      outline: none;

      &::placeholder {
        font-weight: 900;
        color: #6f6f6f;
      }
    }

    & > button {
      width: 100%;
      font-weight: 700;
    }
  }
}
