.score-table-td,
.score-table-th,
.score-table {
  border: 1px solid #d6d6d6;
  border-collapse: collapse;
}

.score-table-header-row {
  border: none !important;
  background-color: inherit !important;
}

.score-table-row {
  background-color: inherit !important;
  border: none !important;
}

.score-table-header-row {
  background-color: rgb(214, 214, 214) !important;
}

.card {
  background-color: #ffffff;
  padding: 1.5rem 2rem;

  .sub-header {
    color: #909090;
    font-size: 0.8rem;
    margin-top: 1rem;
  }
}
