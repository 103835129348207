.goal-setting-header {
  font-weight: 700;
  margin-bottom: 1rem;
}

.td-cell {
  overflow: scroll;
  word-wrap: break-all;
}

.submit-goal-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  button {
    padding: 0.5rem 2rem;
    height: 3.5rem;
    width: 22rem;
    font-weight: 700;
  }
}

.a-review-filter-board {
  margin-top: 3rem;

  p {
    font-weight: 900 !important;
  }
}
