.approver {
  margin-bottom: 1rem;

  .border-bottom {
    border-bottom: 1px solid #caced5;
  }

  .details-header {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0.8rem 1.5rem;

    button {
      width: 6rem;
      height: 2rem;
      border: 1px solid #b2d4b2;
      cursor: pointer;
      background-color: #c0dbc031;
      color: #6f6f6f;
    }

    & .user-details-container {
      display: flex;

      .user-name {
        text-align: right;
        font-weight: 900 !important;
      }

      .title {
        color: #9d9d9d;
      }
      & img {
        border-radius: 50%;
        margin-left: 0.5rem;
      }
    }
  }

  .details-container {
    hr {
      border: 1px solid #d6d6d6;
    }
    .t-header {
      padding: 0.8rem 1.5rem 0 1.5rem;
      & .t-cell {
        font-weight: 900;
      }
    }

    .comments-section {
      padding: 0.8rem 1.5rem 1.5rem 1.5rem;

      & > h3 {
        color: #1c811c;
        font-weight: 900;
        line-height: 1.5;
      }
    }
  }
}

.withborder {
  border: 1px solid #caced5;
  border-radius: 5px;
}
