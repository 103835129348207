.score-table-td,
.score-table-th,
.score-table {
  border: 1px solid #d6d6d6;
  border-collapse: collapse;
}

.score-table {
  position: relative;
  .drawer {
    border-left: 1px solid #d6d6d6;
    background-color: #ffffff;
    height: 100%;
    width: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
    overflow-y: auto;
    // padding-top: 0.6rem;

    & .header {
      height: 72px;
      background-color: rgb(214, 214, 214) !important;
      font-weight: 700;
      padding: 0.6rem 0.5rem;
    }
  }
}

.score-table-header-row {
  border: none !important;
  background-color: inherit !important;
}

.score-table-row {
  background-color: inherit !important;
  border: none !important;
}

.score-table-header-row {
  background-color: rgb(214, 214, 214) !important;
}

.card {
  background-color: #ffffff;
  padding: 1.5rem 2rem;

  .sub-header {
    color: #909090;
    font-size: 0.8rem;
    margin-top: 1rem;
  }
}
