.reviewer {
  display: flex;
  padding: 0.5rem;
  border-bottom: 1px solid #d6d6d6;

  & button {
    height: 2.3rem;
    width: 5rem;
    margin-left: 1rem;
  }

  & .name-and-title {
    padding: 0.5rem;

    .user-name {
      text-align: left;
      font-weight: 900 !important;
    }

    .title {
      color: #9d9d9d;
      font-size: 0.6rem;
    }

    .date-time {
      display: block;
      font-size: 0.6rem;
    }
  }

  & img {
    border-radius: 50%;
    margin-left: 0.2rem;
    height: 3rem;
    width: 3rem;
  }
}

.reviewed {
  display: flex;
  border-bottom: 1px solid #d6d6d6;

  .score-container {
    font-size: 0.8rem;
    word-wrap: break-word;
    padding: 0.5rem 0;
    color: #43425d;
    flex-grow: 1;

    .score-title {
      display: flex;
      justify-content: center;
      height: 2.7rem;
      border-bottom: 1px solid #d6d6d6;
    }
    .score {
      display: flex;
      justify-content: center;
      height: 1.8rem;
      padding: 0.5rem 0;
    }
  }

  .reviewer-meta-data {
    display: flex;
    padding: 0.5rem;
    border-right: 1px solid #d6d6d6;

    & .name-and-title {
      padding: 0.5rem;

      .user-name {
        text-align: left;
        font-weight: 900 !important;
      }

      .title {
        color: #9d9d9d;
        font-size: 0.6rem;
      }

      .date-time,
      .view-details {
        display: block;
        font-size: 0.6rem;
      }

      .view-details {
        border: 1px solid #73ba73;
        background-color: #97e6976c;
        padding: 0.25rem 0.75rem;
        width: 6rem;
        border-radius: 15px;
        text-align: center;
        cursor: pointer;
      }
    }

    & img {
      border-radius: 50%;
      margin-left: 0.2rem;
      height: 3rem;
      width: 3rem;
    }
  }

  & button {
    height: 2.3rem;
    width: 5rem;
    margin-left: 1rem;
  }
}
