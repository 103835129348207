.collapsible-container-user {
  margin-bottom: 1rem;
  width: 100%;

  details {
    & summary {
      list-style: none;
      background-color: #ffffff;
      height: 8rem;
      padding: 0.5rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f0e8e8;

      .expand {
        background-color: #ff7108;
      }

      .collapse {
        background-color: #43425d;
      }

      .collapse-expand-trigger {
        cursor: pointer;
        display: inline-block;
        border-radius: 4px;
        color: #ffffff;
        padding: 0.6rem 1.3rem;
        height: 2.2rem;
      }

      .icon-title-container {
        display: inline-block;
        position: relative;
        padding: 0.2rem 2.25rem 0;
        height: 2rem;

        h2 {
          font-weight: bolder !important;
          color: #43425d;
        }

        .collapsible-icon {
          position: absolute;
          left: 0;
          top: 0rem;
        }
      }
    }

    & .collapsible-content {
      background-color: #ffffff;
      padding: 1.4rem 1.8rem;
    }
  }
}
