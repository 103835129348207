.declaration-container {
  background-color: #ffffff;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;

  .declaration {
    width: 60%;

    h3 {
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.8rem;
      color: #909090;
      line-height: 1.5;
    }
  }

  .signature {
    width: 40%;
    margin-left: 10rem;
  }
}
